.home {
  
  @include breakpoint-max(990px) {
    min-width: 70% !important;
  }
  
  @include breakpoint-max(825px) {
    min-width: 68% !important;
  }

  > .carousel {
    position: relative;
    width: 100%;
    min-height: 180px;
    overflow: hidden;

    > div {
      position: relative;
      width: 100%;
      aspect-ratio: 864/297;

      > a {
        display: inline-block;

        @include clickable-image-hover-effect();
      }

      img {
        width: 100%;
        height: auto;
      }

      .text {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    > ul {
      position: absolute;
      bottom: 20px;
      left: 30px; 
      z-index: 2;

      @include breakpoint-max(1100px) {
        bottom: 10px;
      }

      > li {
        width: 15px;
        height: 15px;
        float: left;
        margin-right: 10px;
        border: 1px solid $orange-light;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 0 10px rgb(255 255 255 / 40%);

        &.active {
          pointer-events: none;
        }

        &.active, &:hover {
          background: $orange-light;
        }
      }
    }
  }

  > .banners {
    width: 100%;
    margin-top: 20px;
    min-height: 222px;
    // overflow: hidden;

    @include breakpoint(1220px) {
      min-height: 345px;
    }

    > ul {
      background: #f1f1f1;
      border-top: 1px solid #ebebeb;
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      min-height: 40px;

      > li {
        float: left;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        border-bottom: 1px solid #ebebeb;
        border-left: 1px solid #f1f1f1;
        border-right: 1px solid #f1f1f1;
        @include no-select();

        > .body {
          padding: 13px;
          border-top: 3px solid #f1f1f1;

          @include breakpoint-max(1111px) {
            padding: 12px;
          }

          @include breakpoint-max(989px) {
            padding: 10px 11px;
            font-size: 11px;
          }

          @include breakpoint-max(950px) {
            padding: 10px;
          }

          @include breakpoint-max(904px) {
            padding: 10px 8px;
          }

          @include breakpoint-max(850px) {
            padding: 10px 5px;
          }

          @include breakpoint-max(790px) {
            padding: 10px 4px;
          }

          > .long {

            @include breakpoint-max(1110px) {
              display: none;
            }
          }

          > .short {
            display: none;

            @include breakpoint-max(1110px) {
              display: inline-block;
            }
          }
          
          &:last-child {
            margin-right: 0;
          }
        }
          
        &:first-child {
          border-left: 0;

          &.active {
            border-left: 0;
          }
        }

        &.active {
          background: #fff;
          border-left: 1px solid #ebebeb;
          border-right: 1px solid #ebebeb;

          > .body {
            border-top: 3px solid $orange-light;
          }
        }
      }
    }

    > .banner-target {
      z-index: 1;
      min-height: 182px;
    }
  }

  > .retail-shop {
    width: 100%;
    margin: 20px 0 30px;

    > h2 {
      margin-bottom: 15px;

      @include breakpoint-max(1200px) {
        margin: 10px;
        text-align: center;
      }
    }

    > .body {
      position: relative;

      @include breakpoint-max(1200px) {
        width: 452px;
        margin: 0 auto;
      }

      > .left {
        float: left;
        width: calc(100% - 452px);
        height: 367px;
        padding: 10px 18px;
        background: #fff;
        border: 1px solid #f1f1f1;

        @include breakpoint-max(1200px) {
          float: none;
          width: 452px;
          height: auto;
        }

        > .schedule {
          > .address-wrap {
            padding-right: 10px;
  
            > .address {
              margin-bottom: 10px;
              display: flex;
              color: #000;
              font-size: 13px;
  
              > img {
                margin-right: 10px;
              }

              > .body {
                width: calc(100% - 50px);

                > span {
                  display: inline-block;
                }
    
                > .storefront {
                  margin: 0;
                  font-weight: normal;
                  font-style: italic;
                  font-size: 11.5px;
                  line-height: 1.4;
                }
              }
            }
          }
        }

        > .link-to {
          cursor: pointer;
        }

        > .buttons {
          margin: 15px 0;
          display: flex;
          justify-content: space-between;

          > a {
            text-align: center;
            font-size: 11px;
          }
        }

        > ul {
          margin: 0 0 5px;

          > li {
            line-height: 22px;
            font-size: 13px;
            font-weight: bold;

            > img {
              margin-right: 3px;
              vertical-align: -3px;
            }
          }
        }
      }

      > .right {
        position: relative;
        float: right;
        height: 367px;
        z-index: 1;
        background: #fff;

        &.desktop {
          display: block;
        }

        &.tablet {
          display: none;
        }

        @include breakpoint-max(1200px) {
          width: 452px;
          float: none;

          &.desktop {
            display: none;
          }

          &.tablet {
            display: block;
          }
        }

        > figure {
          width: 100%;

          > a {
            display: inline-block;

            @include clickable-image-hover-effect();
          }
          
          > img {
            width: 100%;
          }
        }

        > ul {
          position: absolute;
          left: 50%;
          bottom: 20px;
          width: 70px;
          margin-left: -35px;
          z-index: 2;
    
          > li {
            width: 15px;
            height: 15px;
            float: left;
            margin-right: 10px;
            border: 1px solid $orange-light;
            border-radius: 8px;
            cursor: pointer;
            box-shadow: 0 0 10px rgb(255 255 255 / 40%);
    
            &.active {
              pointer-events: none;
            }
    
            &.active, &:hover {
              background: $orange-light;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        > a {
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: 225px;
          padding: 10px;
          background: #fff;
          opacity: .9;
          font-size: 12px;
          color: #000;
          border-radius: 3px;
          line-height: 14px;
      
          @include breakpoint-max(800px) {
            width: 215px;
            padding: 10px 5px;
          }

          > img {
            margin-right: 7px;
          }
        }
      }
    }
  }

  >.shipping-vault {
    width: 100%;
    margin-top: 20px;

  } 

  > .active-auction {
    margin: 20px 0 15px;

    // @include breakpoint-max(1200px) {
    //   padding: 0 10px;
    // }
    @include breakpoint(988px) {
      padding: 0 10px;
    }

    > h2 {
      margin-bottom: 15px;

      @include breakpoint-max(1100px) {
        font-size: 16px;
      }

      @include breakpoint-max(1000px) {
        margin-bottom: 10px;
        font-size: 14px;
      }

      @include breakpoint-max(819px) {
        margin-left: 32px;
      }

      > a {
        margin-left: 10px;
        display: inline-block;
        font-size: 13px;
        color: $orange-light;
      }
    }

    > .auction-list {
      align-items: unset;
      margin-top: 0;
      flex-wrap: nowrap;

      @include breakpoint-max(820px) {
        justify-content: space-evenly;
        gap: 0;
      }

      > div {
        
        // @include breakpoint-max(1190px) {
        //   &:not(.wide):last-child {
        //     display: none;
        //   }
        // }

        &:nth-child(3) {
          @include breakpoint-max(1170px) {
            display: none;
          }
        }

        &:nth-child(2) {
          @include breakpoint-max(820px) {
            width: 212px;
          }
        }
      }
    }

    .product-auction {
      width: 210px;
      margin: 0;
      
      @include breakpoint-max(820px) {
        padding-top: 20px;
      }

      @keyframes bellshake {
        0% { transform: rotate(0); }
        15% { transform: rotate(3deg); }
        30% { transform: rotate(-3deg); }
        45% { transform: rotate(2deg); }
        80% { transform: rotate(-2deg); }
        90% { transform: rotate(1deg); }
        100% { transform: rotate(0); }
      }
      
      &:hover, &:focus {
        /* animate bell icon to shake */
        .bell.watchlist {
          animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
          backface-visibility: hidden;
          transform-origin: top right;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .wide {
      width: 422px;

      @include breakpoint-max(820px) {
        width: 212px;
      }
      > a {
        > header {
          > .name {
            font-size: 20px;
          }

          @include breakpoint-max(820px) {
            > .name {
              font-size: 14px;
            }
          }
        }
      }

      > .time-left {
        > .narrow {
          display: none;

          @include breakpoint-max(820px) {
            display: inline;
          }
        }
        > .wider {
          @include breakpoint-max(820px) {
            display: none;
          }
        }
      }
      > .bottom {
        display: flex;
        justify-content: space-evenly;

        > .left {
          > .bid-note {
            margin: 0 0 5px;
            font-size: 11px;
            color: #8D8F92;
          }

          > .actions {
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            
            > input {
              width: 80px;
              padding: 5px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              border: 1px solid #ccc;
              border-right: 0;
              vertical-align: top;
              font-size: 12px;
              color: #231F20;
              background: transparent;
            }
        
            > button {
              width: 80px;
              padding: 7px;
              font-size: 11px;
              border-radius: 0;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              border: 0;
            }
          }
      
          > .bid-count {
            cursor: pointer;
            text-align: center;
            line-height: 1;
            color: $orange-mid;
            font-size: 12px;
          }
        }

        > button {
          width: 190px;
          height: 30px;
          margin-top: 20px;
        }
      }
    }
  }

  > .popular-products {
    margin: 20px 0;

    @include breakpoint(988px) {
      padding: 0 10px;
    }
    // @include breakpoint-max(1200px) {
    //   padding: 0 10px;
    // }

    > h2 {
      margin-bottom: 15px;

      @include breakpoint-max(1100px) {
        //margin-bottom: 20px;
        font-size: 16px;
      }

      @include breakpoint-max(1000px) {
        margin-bottom: 10px;
        font-size: 14px;
      }

      @include breakpoint-max(819px) {
        margin-left: 32px;
      }
    }

    > div {
      display: flex;

      @include breakpoint-max(819px) {
        justify-content: space-evenly;
      }

      > .product {

        @include breakpoint-max(1034px) {
          &:nth-child(4) {
            display: none;
          }
        }

        @include breakpoint-max(819px) {
          margin-right: 0;
          
          &:nth-child(3) {
            display: none;
          }
        }

        > .status {
          text-transform: uppercase;

          > .stock-quantity {
            text-transform: none;
          }
        }
      }
    }
  }

  > .new-products {
    margin: 20px 0;

    @include breakpoint(988px) {
      padding: 0 10px;
    }

    /* @include breakpoint-max(1100px) {
      margin: 40px 0;
    }
    
    @include breakpoint-max(1000px) {
      margin: 30px 0;
    }
    
    @include breakpoint-max(900px) {
      margin: 20px 0;
    } */

    > h2 {
      margin-bottom: 15px;

      @include breakpoint-max(1100px) {
        //margin-bottom: 20px;
        font-size: 16px;
      }

      @include breakpoint-max(1000px) {
        margin-bottom: 10px;
        font-size: 14px;
      }

      @include breakpoint-max(819px) {
        margin-left: 32px;
      }
    }

    > div {
      display: flex;

      @include breakpoint-max(819px) {
        justify-content: space-evenly;
      }

      > .product {

        @include breakpoint-max(1034px) {
          &:nth-child(4) {
            display: none;
          }
        }

        @include breakpoint-max(819px) {
          margin-right: 0;

          &:nth-child(3) {
            display: none;
          }
        }

        > .status {
          text-transform: uppercase;

          > .stock-quantity {
            text-transform: none;
          }
        }
      }
    }
  }

  > .reviews {
    clear: both;
    width: 100%;
    margin: 30px auto;
    padding: 0 15px;

    // @include breakpoint-max(1100px) {
    //   margin: 15px auto;
    // }

    // @include breakpoint-max(1000px) {
    //   margin: 10px auto;
    // }

    > h2 {

      @include breakpoint-max(1100px) {
        font-size: 16px;
      }

      @include breakpoint-max(1000px) {
        font-size: 14px;
      }
    }

    .social-media {
      display: flex;
      align-items: center;

      @include breakpoint-max(1200px) {
        justify-content: center;
        flex-wrap: wrap;
      }

      > a {
        position: relative;
        display: block;
        margin-right: 40px;
        text-align: center;

        @include breakpoint-max(1200px) {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        @include clickable-image-hover-effect-dark();
      }

      > .google {
        // position: relative;
        // display: block;
        // margin-right: 40px;

        // @include breakpoint-max(1200px) {
        //   width: 200px;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        // }

        > img {
          width: 150px;
          height: 55px;
          display: block;
        }

        > .rating-wrap {
          display: flex;
          justify-content: center;
          margin-top: 5px;

          > .rating {
            line-height: 26px;
            color: #E74525;
            font-size: 18px;
            margin: 0 5px 0 0;
          }

          > .rating-star-box {
            position: relative;
            width: 75px;

            > .rating-star-default {
              position: absolute;
              left: 0;
              margin-top: 6px;
              width: auto;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 0;
              list-style: none;
              z-index: 1;

              li {
                display: inline-block;
                
                span {
                  @extend %icon-google-star-orange;
                  margin-left: 3px;
                  z-index: 1;
                }
              }

            }

            > .rating-star {
              position: absolute;
              left: 0;
              margin-top: 6px;
              width: auto;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 0;
              list-style: none;
              z-index: 2;

              li {
                display: inline-block;
                
                span {
                  @extend %icon-google-star-orange-filled;
                  margin-left: 3px;
                  z-index: 2;
                }
              }
            }
          }
        }
          
        .total-reviews {
          font-size: 11px;
          color: #414042;
          margin-top: 5px;
          text-indent: 0;
          text-align: center;
        }
      }

      > .tripadvisor {
        // display: block;
        // text-align: center;
        // margin-right: 40px;

        // @include breakpoint-max(1200px) {
        //   width: 200px;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        // }
          
        > img {
          width: 180px;
          height: 55px;
          display: block;
        }

        > .rating-wrap {
          display: flex;
          justify-content: center;
          margin-top: 5px;

          > .rating {
            line-height: 26px;
            color: #00aa6c;
            font-size: 18px;
            margin: 0 5px 0 0;
          }

          > .rating-star-box {
            position: relative;
            width: 75px;

            > .rating-circle-default {
              position: absolute;
              left: 0;
              margin-top: 7px;
              width: auto;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 0;
              list-style: none;
              z-index: 1;

              li {
                display: inline-block;
                
                span {
                  @extend %icon-tripadvisor-rating;
                  margin-left: 3px;
                  z-index: 1;
                }
              }

            }

            > .rating-circle {
              position: absolute;
              left: 0;
              margin-top: 7px;
              width: auto;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 0;
              list-style: none;
              z-index: 2;

              li {
                display: inline-block;
                
                span {
                  @extend %icon-tripadvisor-rating-full;
                  margin-left: 3px;
                  z-index: 2;
                }
              }
            }
          }
        }

        > .total-reviews {
          margin-top: 6px;
          line-height: 1;
          font-size: 11px;
          color: #414042;
        }
      }

      > .facebook {
        // display: block;
        // text-align: center;
        // margin-right: 40px;

        // @include breakpoint-max(1200px) {
        //   width: 200px;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        // }
          
        > img {
          width: 190px;
          height: 55px;
          display: block;
        }

        > .rating {
          margin: 0;
          padding: 3px 20px;
          display: inline-block;
          text-align: left;
          color: #fff;
          font-size: 18px;
          @extend %icon-fb-star;
        }

        > .total-reviews {
          margin-top: 8px;
          line-height: 1;
          font-size: 11px;
          color: #414042;
        }
      }

      > .trustpilot {
        // display: block;
        // text-align: center;
        // margin-right: 40px;

        // @include breakpoint-max(1200px) {
        //   width: 200px;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        // }
          
        > img {
          width: 190px;
          height: 55px;
          display: block;
        }

        > .rating-wrap {
          display: flex;
          justify-content: center;
          margin-top: 5px;

          > .rating {
            line-height: 26px;
            color: #00aa6c;
            font-size: 18px;
            margin: 0 5px 0 0;
          }

          > .rating-star-box {
            position: relative;
            width: 75px;

            > .rating-star-green-default {
              position: absolute;
              left: 0;
              margin-top: 7px;
              width: auto;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 0;
              list-style: none;
              z-index: 1;

              li {
                display: inline-block;
                
                span {
                  @extend %icon-trustpilot-rating;
                  margin-left: 3px;
                  z-index: 1;
                }
              }

            }

            > .rating-star-green {
              position: absolute;
              left: 0;
              margin-top: 7px;
              width: auto;
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 0;
              list-style: none;
              z-index: 2;

              li {
                display: inline-block;
                
                span {
                  @extend %icon-trustpilot-rating-full;
                  margin-left: 3px;
                  z-index: 2;
                }
              }
            }
          }
        }

        > .total-reviews {
          margin-top: 6px;
          line-height: 1;
          font-size: 11px;
          color: #414042;
        }
      }
    }
  }

  .latest-content {
    margin: 0;

    @include breakpoint-max(990px) {
      margin-right: 10px;
    }

    td {
      vertical-align: top;      
    }

    h2 {
      margin: 0 0 15px;
    }

    h3 {
      margin: 0 0 5px;
      font-size: 12px;
    }

    .latest-content-cell {
      width: 50%;

      table {
        height: 131px;
      }

      img {
        max-width: 200px;
        width: 100%;
      }

      .time {
        color: #676767;
        font-size: 11px;
      }
      
      .latest-inner {
        .latest-content-image {
          width: 35%;
        }

        .latest-content-text {
          width: 65%;
        }
      }

      .latest-content-text {
        padding-left: 13px;
      }

      > div {
        padding: 25px 21px 20px 28px;
        background: #fff;
        border: 1px solid #ebebeb;
      }
    }

    .latest-content-blog {
      padding-right: 5px;
    }

    .latest-content-video {
      padding-left: 5px;
    }
  }
}
